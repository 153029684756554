

/********************/
/*     Buttons      */
/*==================*/
/* Note: If you want to add your own colors, you can do so by going to this tailwind.config.js file  */
.btn {
    @apply py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center cursor-pointer;
    &:disabled {
        @apply cursor-not-allowed;
    }
    &.btn-lg{
        @apply py-2.5 px-6 text-lg;
    }
    &.btn-sm{
        @apply py-[5px] px-4 text-sm;
    }
    /*  Button icons */
    &.btn-icon {
        @apply p-0 h-9 w-9 inline-flex items-center text-center justify-center text-base;
        &.btn-lg {
            @apply h-12 w-12;
        }
        &.btn-sm {
            @apply h-8 w-8;
        }
    }

    /* Button Link */
    &.btn-link {
        @apply relative p-0 border-none after:content-[''] after:absolute after:h-px after:w-0 ltr:after:right-0 rtl:after:left-0 after:bottom-0 ltr:after:left-0 rtl:after:right-0 after:transition-all after:duration-500;

        &:hover {
            @apply after:w-full ltr:after:right-auto rtl:after:left-auto;
        }
    }

    &.btn-primary {
        @apply bg-primary-700 border-primary-700 hover:bg-primary-800 hover:border-primary-800 text-white rounded-md
    }

    &.btn-secondary {
        @apply bg-secondary-600 border-secondary-600 hover:bg-secondary-700 hover:border-secondary-700 text-white rounded-md
    }

    &.btn-danger {
        @apply bg-red-700 border-red-700 hover:bg-red-800 hover:border-red-800 text-white rounded-md
    }
}
