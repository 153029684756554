.user-content {
    p {
        @apply text-justify mb-2
    }

    h3 {
        @apply md:text-3xl text-xl leading-normal mb-4 mt-6 text-gray-900
    }
    h4 {
        @apply md:text-2xl text-xl leading-normal mb-4 mt-6 text-gray-900
    }
    h5 {
        @apply  md:text-xl text-lg leading-normal mb-4 mt-6 text-gray-900
    }
}
