/*********************************/
/*           Custom           */
/*===============================*/

.breadcrumb .breadcrumb-item::after {
    color: rgb(220 220 220 / var(--tw-text-opacity)) !important;
}

#topnav .navigation-menu > li > a {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
    right: -5px !important;
}

.background-effect .circles li {
    background-color: rgb(0 55 81 / 0.5) !important;
}